import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';
import { firstValueFrom, map, Observable, ReplaySubject, tap } from 'rxjs';
import * as R from 'runtypes';
import { Literal, Static, Runtype } from 'runtypes';

import { URLS } from './app.constant';
import { SuccessOnlyResponse } from '../utils/utils';
import { extractError } from './message.service';
import { USA_ISO3_STRING } from './address.service';
import { defined, Enum } from '../utils/runtypes';

export enum KycStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
}

export enum KycLevel {
  FULL = 'FULL',
  PROTECTED = 'PROTECTED',
  PARTNER = 'PARTNER',
  BASIC = 'BASIC', // deprecated
}

const CurrentUserDetails = R.Record({
  id: R.Number,
  confirmationToken: R.String,
  twoFactorType: R.String,

  zoomMeHandle: R.String.nullable(),
  zoomMeHandleQr: R.String.optional(),
  email: R.String,

  firstName: R.String.nullable(),
  lastName: R.String.nullable(),

  countryCode: R.Number.nullable(),
  mobileNumber: R.String.nullable(),
  mobilePinSet: R.Boolean,
  mobileVerified: R.Boolean,

  firstLogin: R.Boolean,

  profileSubmitted: R.Boolean,
  kycSubmitted: R.Boolean,
  kycStatus: Enum(KycStatus).nullable(),
  kycLevel: Enum(KycLevel).nullable(),
  passportUploadCount: R.Number,
  socialSecurityNumberCount: R.Number,
  ssnRequired: R.Boolean,
  kycRejectionReason: R.String.nullable(),

  tsAndCsRequired: R.Boolean,
  tsAndCsAccepted: R.Boolean,

  bankDetailsSubmitted: R.Boolean,

  exchangeBlocked: R.Boolean,
  transferWithdrawBlock: R.Boolean,
  userTradeBlocked: R.Boolean,
});
export type CurrentUserDetails = Static<typeof CurrentUserDetails>;

export enum LoginTypes {
  DIRECT = 'DIRECT',
  CODE_2FA = 'GOOGLE_AUTHENTICATION',
  EMAIL_OTP = 'EMAIL_MOBILE_OTP_AUTHENTICATION',
}

export const LoginSuccess = CurrentUserDetails.extend({
  authorization: R.String,
  refreshToken: R.String,
});
export type LoginSuccess = Static<typeof LoginSuccess>;

const LoginSuccessResponse = SuccessOnlyResponse(LoginSuccess);

export const TwoFaSetup = R.Record({
  base64Image: R.String,
  key: R.String,
});
export type TwoFaSetup = Static<typeof TwoFaSetup>;

const TwoFaSetupResponse = SuccessOnlyResponse(TwoFaSetup);

export enum OtpType {
  WITHDRAW = 'WITHDRAW',
  TRADE = 'TRADE',
  ZOOMME = 'ZOOMME',
  EXCHANGE = 'EXCHANGE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  SHOW_2FA_QR_CODE = 'SHOW_2FA_QR_CODE',
  RESET_2FA_SECRET = 'RESET_2FA_SECRET',
  CARD_MANAGEMENT = 'CARD_MANAGEMENT',
}

const RefreshTokenResponse = SuccessOnlyResponse(R.Record({ accessToken: R.String, refreshToken: R.String }));

const CurrentUserDetailsResponse = SuccessOnlyResponse(CurrentUserDetails);

const GoogleAuthEnabledResponse = SuccessOnlyResponse(R.Record({ isGoogleAuthEnabled: R.Boolean }));

export class GoogleAuthData {
  public otp: string;

  constructor(readonly qrCode: SafeUrl, readonly key: string) {}
}

class InflightTokenRefreshPromise {
  constructor(readonly resolve: (token: string) => void, readonly reject: (reason: unknown) => void) {}
}

/**
 * !!DO NOT EXTEND!!
 *
 * It is not safe to extend this service now that it's used directly in the core library. Any instance (non-static)
 * state will not be a true singleton as client subclasses will generate their own instances.
 *
 * That could probably be fixed by using interfaces and DI tokens (https://angular.io/api/core/InjectionToken) as
 * interfaces don't exist at runtime but the additional code isn't worth it at the moment.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // FIXME for historic reasons this only tracks login status *changes* and not the initial status
  private loggedInStatus = new ReplaySubject<boolean>();
  $loggedIn = this.loggedInStatus.asObservable();

  private _loginConfirmationToken: string | null = null;
  private _forgetPasswordToken: string | null = null;
  private _userDetails: CurrentUserDetails | null = null;
  private _authToken: string | null = null;
  private _refreshToken: string | null = null;
  private _googleAuthEnabled: Promise<boolean> | null = null;
  private _inflightRefreshPromises = new Array<InflightTokenRefreshPromise>();
  private _useSessionStorage = true;

  constructor(private router: Router, private http: HttpClient) {
    try {
      const storedToken = sessionStorage.getItem('auth');
      if (storedToken) {
        this._authToken = storedToken;
      }

      const storedRefreshToken = sessionStorage.getItem('refresh');
      if (storedRefreshToken) {
        this._refreshToken = storedRefreshToken;
      }

      this.publishLoggedInStatus(this.loggedIn);

      const storedUserDetails = sessionStorage.getItem('userDetail');
      if (storedUserDetails) {
        const validatedDetails = CurrentUserDetails.validate(JSON.parse(storedUserDetails));
        // Silently ignore invalid details as they'll be replaced soon
        if (validatedDetails.success) {
          this._userDetails = validatedDetails.value;
        }
      }

      const storedLoginConfirmationToken = sessionStorage.getItem('loginConfirmationToken');
      if (storedLoginConfirmationToken) {
        this._loginConfirmationToken = storedLoginConfirmationToken;
      }
    } catch (e) {
      // Session storage access isn't available (e.g. this can happen in the payment iframe, depending
      // on browser security settings). We should continue to work without session storage.
      this._useSessionStorage = false;
    }
  }

  setToken(token: string | null): void {
    this._authToken = token;
    if (this._useSessionStorage) {
      if (token) {
        sessionStorage.setItem('auth', token);
      }
    } else {
      sessionStorage.removeItem('auth');
    }
  }

  getToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this._authToken && !this.tokenExpired(this._authToken)) {
        resolve(this._authToken);
      } else if (this._refreshToken && !this.tokenExpired(this._refreshToken)) {
        this._inflightRefreshPromises.push(new InflightTokenRefreshPromise(resolve, reject));
        if (this._inflightRefreshPromises.length == 1) {
          this.sendTokenRefresh();
        }
      } else {
        this.clearAuthData();
        this.publishLoggedInStatus(false);
        reject('Your login has expired.');
      }
    });
  }

  getRefreshToken(): string | null {
    return this._refreshToken;
  }

  reloadTokens(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this._refreshToken && !this.tokenExpired(this._refreshToken)) {
        this._inflightRefreshPromises.push(new InflightTokenRefreshPromise(resolve, reject));
        if (this._inflightRefreshPromises.length == 1) {
          this.sendTokenRefresh();
        }
      } else {
        this.clearAuthData();
        reject('Your login has expired.');
      }
    });
  }

  private sendTokenRefresh(): void {
    this.http
      .post(URLS.refreshToken, { token: this._refreshToken })
      .pipe(map(RefreshTokenResponse.check))
      .subscribe({
        next: (response) => {
          const token = response.responseObject.accessToken;
          this.setToken(token);
          this.setRefreshToken(response.responseObject.refreshToken);
          this._inflightRefreshPromises.forEach((r) => r.resolve(token));
          this._inflightRefreshPromises = new Array<InflightTokenRefreshPromise>();
        },
        error: (error) => {
          // The tokens are no longer valid
          this.clearAuthData();
          this._inflightRefreshPromises.forEach((r) => r.reject(error));
          this._inflightRefreshPromises = new Array<InflightTokenRefreshPromise>();
        },
      });
  }

  reloadUserData(): Promise<CurrentUserDetails> {
    return new Promise<CurrentUserDetails>((resolve, reject) => {
      this._inflightRefreshPromises.push(
        new InflightTokenRefreshPromise(
          () => {
            this.http
              .get(URLS.reloadLoginResponse)
              .pipe(map(CurrentUserDetailsResponse.check))
              .subscribe({
                next: (response) => {
                  const details = response.responseObject;
                  this.setLoggedInUserDetails(details);
                  resolve(details);
                },
                error: reject,
              });
          },
          (error) => reject(error)
        )
      );
      if (this._inflightRefreshPromises.length == 1) {
        this.sendTokenRefresh();
      }
    });
  }

  public get usaCustomer(): boolean {
    const countryCode = this._authToken ? parseTokenBody(this._authToken, LoginToken).countryIso3Code : null;
    return defined(countryCode) && countryCode === USA_ISO3_STRING;
  }

  // TODO these should be exposed in some more generic manner so that new getters are not required in these simple cases
  public get seePrivateIntruments(): boolean {
    return this.hasRole('AUTH_TRADE_PRIVATE');
  }

  public get seeTaxData(): boolean {
    return this.hasRole('AUTH_TAX_EXPORT');
  }

  public get seeEarn(): boolean {
    return this.hasRole('AUTH_EARN_WALLET');
  }

  public get seeMarginTrading(): boolean {
    return this.hasRole('AUTH_MARGIN_TRADING') || this.countrySupportsMarginTrading;
  }

  public get performZenusAchWithdrawal(): boolean {
    return this.usaCustomer && this.hasRole('AUTH_ZENUS_ACH_WITHDRAWAL');
  }

  public get performZenusAchDeposit(): boolean {
    return this.usaCustomer && this.hasRole('AUTH_ZENUS_ACH_DEPOSIT');
  }

  public get performZenusWireWithdrawal(): boolean {
    return this.hasRole('AUTH_ZENUS_WIRE_WITHDRAWAL');
  }

  public get performZenusWireDeposit(): boolean {
    return this.hasRole('AUTH_ZENUS_WIRE_DEPOSIT');
  }

  public get performCrossRiverAchWithdrawal(): boolean {
    return this.usaCustomer && this.hasRole('AUTH_CROSS_RIVER_ACH_WITHDRAWAL');
  }

  public get performCrossRiverAchDeposit(): boolean {
    return this.usaCustomer && this.hasRole('AUTH_CROSS_RIVER_ACH_DEPOSIT');
  }

  public get performCrossRiverWireWithdrawal(): boolean {
    return this.hasRole('AUTH_CROSS_RIVER_WIRE_WITHDRAWAL');
  }

  public get performCrossRiverWireDeposit(): boolean {
    return this.hasRole('AUTH_CROSS_RIVER_WIRE_DEPOSIT');
  }

  public get highVolumeTrader(): boolean {
    if (this._authToken) {
      const bodyJson = parseTokenBody(this._authToken, LoginToken);
      return bodyJson.highVolumeTrader;
    } else {
      return false;
    }
  }

  public get sendUSD(): boolean {
    if (this._authToken) {
      const bodyJson = parseTokenBody(this._authToken, LoginToken);
      return bodyJson.sendUSD;
    } else {
      return false;
    }
  }

  public get performTargetedTrade(): boolean {
    return this.hasRole('AUTH_TRADE_TARGETED');
  }

  public get seeCustomerCards(): boolean {
    return this.hasRole('ADMIN_BANK_CARD_VIEW');
  }

  public get manageCustomerCards(): boolean {
    return this.hasRole('ADMIN_BANK_CARD_MANAGE');
  }

  public get manageHeldFunds(): boolean {
    return this.hasRole('ADMIN_HOLD_MANAGEMENT');
  }

  public get isUserKyc(): boolean {
    const userDetail = this.getLoggedInUserDetails();
    return defined(userDetail) && userDetail.kycSubmitted && userDetail.kycStatus === 'VERIFIED';
  }

  public hasRole(role: string): boolean {
    if (this._authToken) {
      const bodyJson = parseTokenBody(this._authToken, LoginToken);
      return bodyJson.groups && bodyJson.groups.indexOf(role) >= 0;
    } else {
      return false;
    }
  }

  public sentOtpToUser(otpType: OtpType): Observable<unknown> {
    return this.http.get(URLS.sendOTP + `?otpType=${otpType}`);
  }

  public getGoogleAuthStatusAPI(): Promise<boolean> {
    if (this._googleAuthEnabled === null) {
      this._googleAuthEnabled = firstValueFrom(
        this.http.get(URLS.googleAuthStatus).pipe(
          map(GoogleAuthEnabledResponse.check),
          map((response) => response.responseObject.isGoogleAuthEnabled)
        )
      );
    }
    return this._googleAuthEnabled;
  }

  submitLoginGoogleOtp(otp: string): Observable<SuccessOnlyResponse<LoginSuccess>> {
    const txn = {
      authKey: otp,
      confirmationToken: this.loginConfirmationToken,
      loginType: LoginTypes.CODE_2FA,
    };
    return this.http.put(URLS.submitGoogleOTP, txn).pipe(map(LoginSuccessResponse.check));
  }

  public googleAuthEnableAPI(otp: number): Observable<unknown> {
    const dataApi = {
      authKey: otp,
      deviceType: 'WEB',
      googleAuthentication: true,
      loginType: LoginTypes.CODE_2FA,
    };

    return this.http.put(URLS.googleAuthEnable, dataApi).pipe(
      tap(() => {
        this._googleAuthEnabled = Promise.resolve(true);
      })
    );
  }

  public googleAuthViewQR(otp: number): Observable<SuccessOnlyResponse<TwoFaSetup>> {
    return this.http.post(URLS.googleAuthView, { authKey: otp }).pipe(map(TwoFaSetupResponse.check));
  }

  public googleAuthStartReset(otp: number): Observable<SuccessOnlyResponse<TwoFaSetup>> {
    return this.http.post(URLS.googleAuthStartReset, { authKey: otp }).pipe(map(TwoFaSetupResponse.check));
  }

  public googleAuthConfirmReset(otp: number): Observable<void> {
    return this.http.post<void>(URLS.googleAuthConfirmReset, { authKey: otp });
  }

  private tokenExpired(token: string): boolean {
    const bodyJson = parseTokenBody(token, JWT);
    return bodyJson.exp * 1000 <= new Date().getTime() + 30000;
  }

  setRefreshToken(token: string): void {
    this._refreshToken = token;
    if (this._useSessionStorage) {
      sessionStorage.setItem('refresh', token);
    }
  }

  public get loggedIn(): boolean {
    return this._refreshToken !== null && typeof this._refreshToken !== 'undefined' && !this.tokenExpired(this._refreshToken);
  }

  logout(): void {
    this._loginConfirmationToken = null;
    this._userDetails = null;
    this._authToken = null;
    this._refreshToken = null;
    this._googleAuthEnabled = null;
    if (this._useSessionStorage) {
      sessionStorage.removeItem('auth');
      sessionStorage.removeItem('refresh');
    }
    this.router.navigateByUrl('landing');
  }

  clearAuthData(): void {
    this._loginConfirmationToken = null;
    this._userDetails = null;
    this._authToken = null;
    this._refreshToken = null;
    this._googleAuthEnabled = null;
    this.loggedInStatus.next(false);
    if (this._useSessionStorage) {
      sessionStorage.clear();
    }
  }

  getLoggedInUserDetails(): CurrentUserDetails | null {
    return this._userDetails;
  }

  setLoggedInUserDetails(userObj: CurrentUserDetails): void {
    this._userDetails = userObj;
    if (this._useSessionStorage) {
      sessionStorage.setItem('userDetail', JSON.stringify(userObj));
    }
  }

  public set loginConfirmationToken(loginConfirmationToken: string | null) {
    if (this._useSessionStorage && loginConfirmationToken) {
      sessionStorage.setItem('loginConfirmationToken', loginConfirmationToken);
    }
    this._loginConfirmationToken = loginConfirmationToken;
  }

  public get loginConfirmationToken(): string | null {
    return this._loginConfirmationToken;
  }

  public get forgetPasswordToken(): string | null {
    return this._forgetPasswordToken;
  }

  public set forgetPasswordToken(forgetPasswordToken: string | null) {
    this._forgetPasswordToken = forgetPasswordToken;
  }

  storeAuthDataOnLogin(loginResponse: LoginSuccess): void {
    if (loginResponse.authorization !== null) {
      this.setToken(loginResponse.authorization);
    }
    if (loginResponse.refreshToken !== null) {
      this.setRefreshToken(loginResponse.refreshToken);
    }
    this.setLoggedInUserDetails(loginResponse);
  }

  publishLoggedInStatus(loggedIn: boolean): void {
    this.loggedInStatus.next(loggedIn);
  }

  private get countrySupportsMarginTrading(): boolean {
    let supports = false;

    if (this._authToken) {
      const bodyJson = parseTokenBody(this._authToken, LoginToken);
      supports = bodyJson.countrySupportsMarginTrading === true;
    }

    return supports;
  }
}

export const JWT = R.Record({ exp: R.Number });
const LoginToken = JWT.extend({
  countryIso3Code: R.String.optional(),
  highVolumeTrader: R.Boolean,
  groups: R.Array(R.String),
  countrySupportsMarginTrading: R.Boolean,
  sendUSD: R.Boolean,
  corporate: R.Boolean,
}).asReadonly();

export const parseTokenBody = <T>(token: string, verifier: Runtype<T>): T => {
  const body = token.split('.')[1];
  return verifier.check(JSON.parse(atob(body)));
};

const Error401 = R.Record({
  status: Literal(401),
  error: R.Record({ error: R.Record({ errorMessage: R.String.optional() }).optional() }).optional(),
});

export const handleLoginError = (error: unknown): string | null => {
  if (Error401.guard(error)) {
    if (error.error?.error?.errorMessage?.includes('due to repeated login failures')) {
      return error.error.error.errorMessage;
    }
    return 'Account locked, please raise a support ticket to unlock your account';
  }
  return extractError(error);
};

export const passwordValidators = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(64),
  Validators.pattern(/^[^\s]+$/),
  Validators.pattern(/[A-Z]/),
  Validators.pattern(/[a-z]/),
  Validators.pattern(/\d/),
  Validators.pattern(/\W/),
];
