import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import * as R from 'runtypes';
import { Static } from 'runtypes';

import { DepositWithdrawalFee, FeeCalculation, GatewayFeeType } from '../entities/fees';
import { URLS } from './app.constant';
import { Enum } from '../utils/runtypes';

const ApiFeeCalculation = Enum(FeeCalculation);
const ApiGatewayFeeType = Enum(GatewayFeeType);

const ApiWithdrawalFee = R.Record({
  id: R.Record({
    gatewayFeeTypeEn: ApiGatewayFeeType,
  }),
  feeCalculationEn: ApiFeeCalculation,
  fixedFeeAmt: R.Number.nullable(),
  percentageFeeAmt: R.Number.nullable(),
});
export type ApiWithdrawalFee = Static<typeof ApiWithdrawalFee>;

const ApiFeeTier = R.Record({
  name: R.String,
  requiredZoomTokens: R.Number,
  simpleTradeFeePct: R.Number.nullable(),
});
export type FeeTier = Static<typeof ApiFeeTier>;

@Injectable({
  providedIn: 'root',
})
export class FeesService {
  private cachedFees = new Map<string, Observable<Array<DepositWithdrawalFee>>>();

  constructor(private http: HttpClient) {}

  public getDepositWithdrawalFees(currency: string, network?: string): Observable<DepositWithdrawalFee[]> {
    const key = `${currency}/${network}`;
    let fees = this.cachedFees.get(key);
    if (!fees) {
      fees = this.http.get(`${URLS.depositWithdrawalFeesUrl}${currency}${selectNetwork(network)}`).pipe(
        // filter out fee types we're not interested in
        map(R.Array(R.Unknown).check),
        map((fees) => fees.filter(ApiWithdrawalFee.guard)),
        map((raw) => raw.map(DepositWithdrawalFee.build)),
        shareReplay(1)
      );
      this.cachedFees.set(key, fees);
    }
    return fees;
  }

  public getFeeOfType(fees: DepositWithdrawalFee[], type: GatewayFeeType): DepositWithdrawalFee | undefined {
    return fees.find((f) => f.feeType == type);
  }

  public getFeeTiers(): Observable<Array<FeeTier>> {
    return this.http.get(URLS.feeTiers).pipe(map(R.Array(ApiFeeTier).check));
  }
}

const selectNetwork = (network?: string) => (network ? `?network=${network}` : '');
