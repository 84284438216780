import { BigNumber } from 'bignumber.js';

import { zeroPadNumberForDisplay } from '../utils/number';

export enum CurrencyType {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
  STOCK = 'STOCK',
}

/* Coins which aren't available as SVG *must* be listed here */
const missingSvg = [
  'TRUMP',
];

export class Currency {
  static readonly USD: string = 'USD';
  static readonly ZOOM: string = 'ZOOM';

  readonly image = `./assets/image/icons/${this.id}.${missingSvg.indexOf(this.id) >= 0 ? 'png' : 'svg'}`;

  constructor(
    readonly id: string,
    readonly currencyNm: string,
    readonly currencyType: CurrencyType,
    readonly minorUnitsQty: number,
    readonly displayUnitsQty: number,
    readonly networks: Array<string>,
    readonly publicFg: boolean,
    readonly collateralFg: boolean,
    readonly spendFg: boolean,
    readonly usdZoomMeAlternateFg: boolean,
    readonly walletExplorerUrls: Map<string, string>,
    readonly txnExplorerUrls: Map<string, string>,
    readonly compositeAddress: boolean,
    readonly compositeAddressDisplayName?: string | null,
    readonly compositeAddressStringTagName?: string | null
  ) {}

  public toString(): string {
    return `Currency[id = ${this.id}, currencyNm=${this.currencyNm}, currencyTypeEn=${this.currencyType}, minorUnitsQty=${this.minorUnitsQty}]`;
  }

  displayAmount(amount?: BigNumber | number | null, decimals = this.displayUnitsQty): string {
    return zeroPadNumberForDisplay(amount, decimals);
  }
}
